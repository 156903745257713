import { useFetcher } from '@remix-run/react'

import RenewSubscriptionPanel from '~/modules/account/components/RenewSubscriptionPanel'
import { ContentErrorType } from '~/modules/auth/services/access'
import type { PrintableDownloads, PrintableErrors } from '~/routes/api.printables.download.$id'
import Banner from '~/components/Banner'
import type { DialogProps } from '~/components/Dialog'
import Dialog from '~/components/Dialog'
import SignUpForm from '~/modules/signup/components/SignUpForm'
import ResendActivationLinkButton from '~/modules/signup/components/ResendActivationLinkButton'
import type { PrintableForDownload, PrintableDownloadTriggerRenderer } from './DefaultButton'
import { DefaultButton } from './DefaultButton'
import PrintableFileList from './PrintableFileList'

export type CustomThumbnail = {
  name: string
  urlMd: string
  urlFull: string
}

interface PrintableImageButtonProps extends Omit<DialogProps, 'title' | 'trigger' | 'content'> {
  printable: PrintableForDownload
  buttonComponent?: PrintableDownloadTriggerRenderer
  customThumbnail?: CustomThumbnail
  title?: string
}

const DialogWrapper = ({
  printable,
  buttonComponent = DefaultButton,
  customThumbnail,
  title,
  ...rest
}: PrintableImageButtonProps) => {
  const { data, submit } = useFetcher<PrintableDownloads | PrintableErrors>()

  const getPrintableDetails = () => {
    const actionUrl = `/api/printables/download/${printable.id}`

    submit(null, {
      action: actionUrl,
      method: 'get'
    })
  }

  const errors = data && 'errors' in data ? (data as PrintableErrors).errors : undefined
  const printableFiles = data && 'files' in data ? (data as PrintableDownloads) : undefined

  return (
    <Dialog
      title={title || `Download ${printable.title}`}
      className="h-auto overflow-hidden"
      trigger={buttonComponent({
        onClick: getPrintableDetails,
        printable,
        customThumbnail: customThumbnail && {
          name: customThumbnail.name,
          url: customThumbnail.urlMd
        }
      })}
      content={
        errors ? (
          <>
            {errors.code === ContentErrorType.SignupUpRequired && (
              <>
                <div className="mx-auto max-w-md space-y-6">
                  <Banner title="Subscription required" intent="info">
                    Sign up today to access hundreds of resources and videos!
                  </Banner>
                </div>

                <div className="mt-5 flex flex-col items-center">
                  <SignUpForm transparentPlanChooser transparentConsentForm />
                </div>
              </>
            )}

            {errors.code === ContentErrorType.AccountNotActivated && (
              <>
                <div className="mx-auto max-w-md space-y-6">
                  <Banner title="Active account required" intent="warning">
                    You haven't activated your account yet. Please check your email for the activation link.
                  </Banner>
                </div>

                <div className="mt-5 flex flex-col items-center">
                  <ResendActivationLinkButton />
                </div>
              </>
            )}

            {(errors.code === ContentErrorType.SubscriptionLapsed ||
              errors.code === ContentErrorType.SubscriptionMissing ||
              errors.code === ContentErrorType.FreeAccessExpired) && (
              <>
                <div className="mx-auto max-w-md space-y-6">
                  <Banner title="Subscription required" intent="warning">
                    Sorry, you need an active subscription to access this resource.
                  </Banner>
                </div>

                <div className="mt-5 flex flex-col items-center">
                  <RenewSubscriptionPanel />
                </div>
              </>
            )}

            {errors.code === ContentErrorType.NotAvailable && (
              <>
                <div className="mx-auto max-w-md space-y-6">
                  <Banner title="Printable not available" intent="warning">
                    Sorry, this resource is not currently available.
                  </Banner>
                </div>
              </>
            )}

            {errors.code === ContentErrorType.InsufficientPermissions && (
              <>
                <div className="mx-auto max-w-md space-y-6">
                  <Banner title="Printable not available" intent="warning">
                    {errors.message}
                  </Banner>
                </div>
              </>
            )}
          </>
        ) : (
          printableFiles && (
            <PrintableFileList
              printable={printableFiles}
              customThumbnail={
                customThumbnail && {
                  name: customThumbnail.name,
                  url: customThumbnail.urlFull
                }
              }
            />
          )
        )
      }
      {...rest}
    />
  )
}

export default DialogWrapper
