import cx from 'classnames'
import * as React from 'react'
import { useSSRLayoutEffect } from '~/hooks/useSSRLayoutEffect'

function BlurrableImage({
  img,
  blurDataUrl,
  className,
  ...rest
}: {
  img: JSX.Element & React.ReactElement<React.ImgHTMLAttributes<HTMLImageElement>>
  blurDataUrl?: string
} & React.HTMLAttributes<HTMLDivElement>) {
  const [visible, setVisible] = React.useState(false)
  const jsImgElRef = React.useRef<HTMLImageElement>(null)

  useSSRLayoutEffect(() => {
    if (jsImgElRef.current?.complete) setVisible(true)
  }, [])

  React.useEffect(() => {
    if (!jsImgElRef.current) return
    if (jsImgElRef.current.complete) return

    let current = true
    jsImgElRef.current.addEventListener('load', () => {
      if (!jsImgElRef.current || !current) return
      setTimeout(() => {
        setVisible(true)
      }, 0)
    })

    return () => {
      current = false
    }
  }, [])

  const jsImgEl = React.cloneElement(img, {
    ref: jsImgElRef,
    className: cx(img.props.className, 'transition-opacity absolute inset-0 z-30', {
      'opacity-0': !visible
    })
  })

  return (
    <div {...rest} className={cx(className, 'relative z-10')}>
      {blurDataUrl ? (
        <>
          <img
            key={blurDataUrl}
            src={blurDataUrl}
            className={cx(img.props.className, 'absolute inset-0 z-10 aspect-video object-cover object-center')}
            alt={img.props.alt}
          />
          <div
            className={cx(
              img.props.className,
              'absolute inset-0 z-20 aspect-video object-cover object-center backdrop-blur-xl'
            )}
          />
        </>
      ) : null}
      {jsImgEl}
      <noscript>{img}</noscript>
    </div>
  )
}

export { BlurrableImage }
